import { create } from 'zustand';

interface PaginationState {
  currentPage: number;
  itemsPerPage: number;
  setCurrentPage: (currentPage: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}
export const usePagination = create<PaginationState>((set) => ({
  currentPage: 1,
  itemsPerPage: 3,
  setCurrentPage: (currentPage) => set({ currentPage }),
  setItemsPerPage: (itemsPerPage) => set({ itemsPerPage }),
}));

