import { create } from 'zustand';
import { ProductType } from '../types/type';

interface ProductsState {
  products: ProductType[];
  filteredProducts: ProductType[];
  setProducts: (products: ProductType[]) => void;
  setFilteredProducts: (filteredProducts: ProductType[]) => void;
}
export const useProducts = create<ProductsState>((set) => ({
  products: [],
  filteredProducts: [],
  setProducts: (products) => set({ products }),
  setFilteredProducts: (filteredProducts) => set({ filteredProducts }),
}));

