import { create } from 'zustand';
import { SubcategoryType } from '../types/type';

interface CategoryFilterState {
  selectedCategory: string | null;
  subcategories: SubcategoryType[] | null;
  selectedType: string;
  setSelectedCategory: (selectedCategory: string | null) => void;
  setSubcategories: (subcategories: SubcategoryType[] | null) => void;
  setSelectedType: (selectedType: string) => void;
  clearCategory: () => void;
}
export const useCategoryFilter = create<CategoryFilterState>((set) => ({
  selectedCategory: null,
  subcategories: null,
  selectedType: 'shop',
  setSelectedCategory: (selectedCategory) => set({ selectedCategory }),
  setSubcategories: (subcategories) => set({ subcategories }),
  setSelectedType: (selectedType) => set({ selectedType }),
  clearCategory: () => set({ selectedCategory: null }),
}));

