import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { router } from './routes';
import { CollectionProvider } from './context/collections';
import { I18nextProvider } from 'react-i18next'
import i18n from './i18ln';
import ReactGA from "react-ga4";

ReactGA.initialize("G-02F7TFP3SD");

function App() {
  return (
    <CollectionProvider>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              // colorPrimary: '#84a8a2',
              colorPrimary: '#1E2D46',
              colorPrimaryHover: '#C3D8E9',
              colorPrimaryTextHover: '#1E2D46',
              colorPrimaryActive: '#5ABBA4',
              borderRadius: 0
              // Alias Token
              // colorBgContainer: '#f6ffed',
            },
            components: {
              Button: {
                contentFontSize: 20,
                paddingInline: 20,
                paddingBlock: 10
              },
              Form: {
                labelColor: '#9A9A9A'
              },
              Menu: {
                itemColor: "rgba(255, 255, 255, 0.88)",
                itemBg: '#2B8EA1',
                itemHoverBg: 'white',
                itemHoverColor: '#5ABBA4',
                itemSelectedColor: '#5ABBA4',
                itemSelectedBg: 'white',
                darkItemBg: '#2B8EA1',
                fontSize: 20,
                padding: 16
              },
              Layout: {
                siderBg: '#2B8EA1',
                headerBg: 'white',
                headerHeight: 40,
              },
              Divider: {
                margin: 8,
                marginLG: 8
              },
              Tabs: {
                titleFontSize: 18,
                titleFontSizeSM: 18,
                titleFontSizeLG: 18
              },
              Table: {
                headerBg: 'transparent',
                headerSplitColor: 'transparent'
              }
            }
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </I18nextProvider>
    </CollectionProvider>
  );
}

export default App;
