import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface CategoryTextProps {
  selected: boolean
}

export const Container = styled.div`
  align-items: center;
  margin-top: 3rem;
  padding: 1rem;
  z-index: 100;
  background-color: ${theme.bg.primary};
  height: 70%;
  overflow-y: scroll;
`;

export const MobileContainer = styled.div`
  position: fixed;
  z-index: 99;
  background-color: ${theme.bg.primary};
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translateX(0);
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;

  a {
    color: white;
    font-family: Raleway;
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: 200;
    cursor: pointer;
    padding: .75rem;

    &:hover {
      // transform: scale(1.2);
      font-weight: 900;
      background-color: white;
      color: ${theme.color.primary};
      transition: 0.4s;
    }

    @media (max-width: 1100px) {
      font-size: 1.5rem;
    }
  }

  .translation-wrapper {
    gap: 1rem;
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  height: 3.063rem;
  width: 14.563rem;
`;

export const Logo = styled.img`
  object-fit: contain;
`;

export const TranslationWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  width: 30dvw;

  &:hover {
    background-color: #e0e0e0;
  }

  .language-text {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const CategoryText = styled.span<CategoryTextProps>`
  color: ${(props) => props?.selected ? theme.color.primary : 'white'};
  background-color: ${(props) => props?.selected ? 'white' : 'transparent'};
  font-family: Raleway;
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: ${(props) => props?.selected ? '900' : '200'};
  cursor: pointer;
  padding: .75rem;

  &:hover {
    font-weight: 900;
    background-color: white;
    color: ${theme.color.primary};
    transition: 0.4s;
  }

  @media (max-width: 1100px) {
    font-size: 1.5rem;
  }
`;