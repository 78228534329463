import { useEffect, useMemo } from "react";
import { Breadcrumb, Col, Row, Space } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import BackArrow from "../../../components/icons/BackArrow";
import {
  BackButton,
  CenterWrapper,
  MainContainer,
  ProductContentContainer,
  ProductDescription,
  ProductDetailHeadingRow,
  // ProductDetailsIconImg,
  ProductDetailsItem,
  ProductTitleContainer,
  // SeeMoreDetailsWrapper,
  StyledCarousel,
  SubImage,
  SubImageWrapper,
} from "./style";
// import { productImages } from "../../../constants";
// import { ProductType } from "../../../types/type";
import { useGetProduct } from "../../../hooks/products/useGetAllProducts";
import Spinner from "../../../components/common/Spinner";
import {
  useCollection,
  useCollectionActions,
} from "../../../context/collections";
import { ProductType } from "../../../types/type";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
// import Icon from '../../../components/Icon/Icon';
// import dimensions from '../../../assets/images/dimensions.png'
// import paint_can from '../../../assets/images/paint_can.png'
// import material from '../../../assets/images/material.png'
import { SlickArrowLeft, SlickArrowRight } from '../../../components/Buttons/CarouselButtons';
import { useTranslation } from 'react-i18next';
import { transformString } from '../../../helpers';
import { HomeOutlined } from '@ant-design/icons';

const Product = () => {
  const { name } = useParams<{ name?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useCollection();
  const { t, i18n } = useTranslation();
  const { addCollection, removeCollection } = useCollectionActions();
  const isPreviousPageProjects = location.state?.fromProjects;
  const { data: product, fetchData, loading } = useGetProduct();
  const productInCollection = state.items.find((pr) => pr?.id === product?.id);

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const onCollections = (product: ProductType) => {
    addCollection(product);
    navigate("/collections");
  };

  useEffect(() => {
    const fetchProductData = async () => {
      if (name) {
        const transformedName = transformString(name);
        console.log('transformedName', transformedName)
        await fetchData(transformedName);
      }
    };

    fetchProductData();
  }, [fetchData, name]);

  return (
    <MainContainer>
      <Breadcrumb
        separator=">"
        items={[
          {
            title: <HomeOutlined />,
            href: 'https://banqa.es/'
          },
          {
            title: 'Shop',
            href: '/shop'
          },
          {
            title: ':name',
            href: '',
          },
        ]}
        params={{ name }}
      />
      <BackButton onClick={() => navigate(-1)} type="text" icon={<BackArrow />}>
        {isPreviousPageProjects ? t('button.back_to_projects') : t('button.back_to_all_products')}
      </BackButton>
      {loading ? (
        <CenterWrapper>
          <Spinner size="large" />
        </CenterWrapper>
      ) : (
        <Row style={{ width: "100%" }} className="main-row" gutter={40}>
          <Col lg={12} md={24}>
            <div>
              {/* <Carousel>
                {product?.images.map((img: string) => (
                  <div>
                    <ProductImage
                      width={400}
                      height={400}
                      loading="lazy"
                      src={img || DEFAULT_IMAGE_PLACEHOLDER}
                      onError={(e: any) => (e.target.src = DEFAULT_IMAGE_PLACEHOLDER)}
                    />
                  </div>
                ))}
              </Carousel> */}
              <StyledCarousel
                arrows
                className="desktop-carousel"
                prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
                nextArrow={<SlickArrowRight currentSlide={0} slideCount={product.images.length} />}
              >
                {product?.images.map((img: string, index: number) => (
                  <SubImageWrapper key={`${img}_${index}`}>
                    <SubImage src={img} />
                  </SubImageWrapper>
                ))}
              </StyledCarousel>
            </div>
            {/* <ImageCarousel
              dimension={{ width: 300, height: 1400 }}
              productImages={product.images}
            /> */}

          </Col>
          <Col lg={12} md={24}>
            <ProductTitleContainer>
              <ProductDetailHeadingRow>
                <Col span={24}>
                  <Space className="heading-space">
                    <h1>
                      {product?.name ||
                        (selectedLanguage === 'ES'
                          ? product?.technicalDescriptionEs || product?.technicalDescription
                          : product?.technicalDescription)
                      }
                    </h1>
                    {productInCollection ? (
                      <SecondaryButton
                        type="primary"
                        onClick={() => removeCollection(product.id)}
                      >
                        {t('button.remove_from_collection')}
                      </SecondaryButton>
                    ) : (
                      <PrimaryButton
                        type="primary"
                        onClick={() => onCollections(product)}
                      >
                        {t('button.add_to_collection')}
                      </PrimaryButton>
                    )}
                  </Space>
                </Col>
              </ProductDetailHeadingRow>
              <Row>
                <h3>{product?.subtitle}</h3>
              </Row>
            </ProductTitleContainer>
            <ProductDescription>
              {product?.description || (selectedLanguage !== 'ES' ? product?.bANQADescriptionENG : product?.bANQADescriptionES)}
            </ProductDescription>
            <ProductContentContainer>
              <Row gutter={[16, 16]}>
                {/* {(product?.contents ?? []).map(
                (content: {
                  image: string | undefined;
                  title: string;
                  description: string;
                }) => (
                  <Col lg={12} md={24}>
                    <ProductContentWrapper>
                      <Image src={content.image} />
                      <div>
                        <label>{content.title}</label>
                        <p>{content.description}</p>
                      </div>
                    </ProductContentWrapper>
                  </Col>
                )
              )} */}
                <Col lg={8} md={24}>
                  <ProductDetailsItem>
                    {/* <Icon name="box" size={68} /> */}
                    <div>
                      <h2>{t('product.item_no')}</h2>
                      <p>{product && (product['itemNo.'] ?? 'No data')}</p>
                    </div>
                  </ProductDetailsItem>
                  {product && product?.portalFinish && product?.portalFinish.length && (
                    <ProductDetailsItem>
                      <div>
                        <h2>{t('product.color')}</h2>
                        <p>{product?.portalFinish}</p>
                      </div>
                    </ProductDetailsItem>

                  )}
                  {/* <ProductDetailsItem>
                    <div>
                      <h2>{t('product.dimensions')}</h2>
                      <p><span>{t('product.height')}:</span> {product && product['boxHeight(Inch)'] && (product['boxHeight(Inch)'].length ? product['boxHeight(Inch)'] : '-')}</p>
                      <p><span>{t('product.length')}:</span> {product && product['boxLength(Inch)'] && (product['boxLength(Inch)'].length ? product['boxLength(Inch)'] : '-')}</p>
                      <p><span>{t('product.width')}:</span> {product && product['boxWidth(Inch)'] && (product['boxWidth(Inch)'].length ? product['boxWidth(Inch)'] : '-')}</p>
                    </div>
                  </ProductDetailsItem> */}
                  {/* <ProductDetailsItem>
                    <div>
                      <h2>{t('product.weight')}</h2>
                      <p>
                        <span>{t('product.weight')}(Kg): </span>
                        {product && product['weight(Kg)'] && (product['weight(Kg)'].length ? product['weight(Kg)'] : '-')}
                      </p>
                      <p>
                        <span>{t('product.weight')}(Lb): </span>
                        {product && product['weight(Lb)'] && (product['weight(Lb)'].length ? product['weight(Lb)'] : '-')}
                      </p>
                    </div>
                  </ProductDetailsItem> */}
                  {/* <ProductDetailsItem>
                    <div>
                      <h2>{t('product.quantity_per')}</h2>
                      <p>
                        {product && product['quantityPer'] && (product['quantityPer'].length ? product['quantityPer'] : '-')}
                      </p>
                    </div>
                  </ProductDetailsItem> */}
                </Col>
                <Col lg={8} md={24}>
                  {product && product?.portalFinish2 !== '' && (
                    <ProductDetailsItem>
                      <div>
                        <h2>{t('product.material')}</h2>
                        <p>{product.portalFinish2}</p>
                      </div>
                    </ProductDetailsItem>
                  )}
                  <ProductDetailsItem>
                    <div>
                      <h2>{t('product.item_dimensions')}</h2>
                      {product && product['itemDimensions(Cm)'] && product['itemDimensions(Cm)'].length && (
                        <p>
                          <span>Cm: </span>
                          {product['itemDimensions(Cm)']}
                        </p>
                      )}
                      {product && product['itemDimensions(Inch)'] && product['itemDimensions(Inch)'].length && (
                        <p>
                          <span>Inch: </span>
                          {product['itemDimensions(Inch)']}
                        </p>
                      )}
                    </div>
                  </ProductDetailsItem>
                </Col>
                <Col lg={8} md={24}>
                  {product && product['indoor/OutdoorUse'] && product['indoor/OutdoorUse'].length && (
                    <ProductDetailsItem>
                      {/* <ProductDetailsIconImg src={dimensions} /> */}
                      <div>
                        <h2>{t('product.indoor')} | {t('product.outdoor')}</h2>
                        <p>
                          {product['indoor/OutdoorUse']}
                        </p>
                      </div>
                    </ProductDetailsItem>
                  )}
                  {/* <ProductDetailsItem>
                    <div>
                      <h2>{t('product.assembly_item')}</h2>
                      <p>
                        {product && product['assemblyItem'] && (product['assemblyItem'].length ? product['assemblyItem'] : '-')}
                      </p>
                    </div>
                  </ProductDetailsItem> */}
                  {/* <ProductDetailsItem>
                    <div>
                      <h2>{t('product.canopy_dimension')}</h2>
                      <p>
                        {product && product['dimensionsCanopy(Inch)'] && (product['dimensionsCanopy(Inch)'].length ? product['dimensionsCanopy(Inch)'] : '-')}
                      </p>
                    </div>
                  </ProductDetailsItem> */}
                </Col>
              </Row>
            </ProductContentContainer>
          </Col>
        </Row>
      )}
    </MainContainer>
  );
};

export default Product;
