import { Navigate, createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/Main';
// import Products from '../pages/Products';
import Product from '../pages/Products/Product';
import Projects from '../pages/Projects';
import Project from '../pages/Projects/Project';
import Shop from '../pages/Shop';
import Collections from '../pages/Collections';


export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/shop" />,
      },
    ],
  },
  {
    path: '/shop',
    element: <MainLayout />,
    children: [
      {
        path: '/shop',
        element: <Shop />,
      },
      {
        path: ':id',
        element: <Product />
      }
    ]
  },
  {
    path: '/products',
    element: <MainLayout />,
    children: [
      {
        path: ':name',
        element: <Product />
      }
    ]
  },
  {
    path: '/projects',
    element: <MainLayout />,
    children: [
      {
        path: '/projects',
        element: <Projects />,
      },
      {
        path: ':id',
        element: <Project />
      }
    ]
  },
  {
    path: '/collections',
    element: <MainLayout />,
    children: [
      {
        path: '/collections',
        element: <Collections />,
      },
    ]
  },
]);
