import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import CustomDrawer from "../../components/Drawer";
import Footer from '../Footer';
import { StyledContent } from './style';
import useGetAllProducts from '../../hooks/products/useGetAllProducts';
import { useEffect } from 'react';
import Spinner from '../../components/common/Spinner';

const MainLayout: React.FC = () => {
  const {
    fetchData: fetchAllProducts,
    loading: isFetchingAllProducts,
  } = useGetAllProducts();

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchAllProducts()
      ])
    })()
  }, [fetchAllProducts]);

  if (isFetchingAllProducts) {
    return <Spinner size="large" />
  }

  return (
    <Layout
      style={{ height: "100%", alignSelf: "center", minHeight: "100dvh" }}
    >
      <Layout>
        <Header />
        <StyledContent>
          <Outlet />
          <CustomDrawer />
        </StyledContent>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
