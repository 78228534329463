import { collection, getDocs, query } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../firebase/config";

export default function useGetAllFiles(): any {
  const [data, setData] = useState<any>();
  const [ids, setIds] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = query(collection(db, "files"));

      await getDocs(docRef)
        .then((docSnap) => {
          const databaseInfo: any[] = [];
          const dataIds: string[] = [];

          docSnap.forEach(async (docsnapshot) => {
            databaseInfo.push({
              ...docsnapshot.data(),
              id: docsnapshot.id,
            });
            dataIds.push(docsnapshot.id);
          });

          setIds(dataIds);
          setData(databaseInfo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return {
    data,
    ids,
    fetchData: refetch,
    loading,
  };
}
