import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Col, Pagination, Row } from "antd";
import {
  AvatarContainer,
  BannerImageContainer,
  CategoryHighlightCard,
  CategoryHighlights,
  MainContainer,
  MaskInfo,
  MasonryLayout,
  MasonryMobileLayout,
  NoDataWrapper,
  OpenPreviewContainer,
  PaginationWrapper,
  ProductCategories,
  ProductImage,
  ProductImageContainer,
  ProductImageOverlay,
  ProductSubcategories,
  ProductsCategoryContainer,
  ProductsMasonryLayout,
  ProductsWrapper,
  ProjectDetailsContainer,
  ProjectListContainer,
  ShopContent,
  StyledCarousel,
  StyledCheckbox,
  SubImageProj,
  SubImageWrapperProj,
} from "./style";
// import ViewImageInfo from "../../components/icons/ViewImageInfo";
import {
  DEFAULT_IMAGE_PLACEHOLDER,
  PRODUCT_LIMIT_PER_MASONRY,
} from "../../constants";
import { ProductType, SubcategoryType } from "../../types/type";
import useGetAllProducts from "../../hooks/products/useGetAllProducts";
import Spinner from "../../components/common/Spinner";
import ImageLayout from "../../components/Projects/ImageLayout";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import useGetAllProjects from "../../hooks/projects/useGetAllProjects";
import useGetAllCategories, { useGetSubCategory } from "../../hooks/categories/useGetAllCategories";
import useGetAllBannerImages, { useGetAllBannerTexts } from '../../hooks/banners/useGetAllBanners';
import { useTranslation } from 'react-i18next';
import { SlickArrowLeft, SlickArrowRight } from '../../components/Buttons/CarouselButtons';
import useGetAllFiles from '../../hooks/files/useFiles';
import { transformToHyphenated } from '../../helpers';
import { useCategoryFilter } from '../../store/useCategoryFilter';
import { useProducts } from '../../store/useProducts';
import { usePagination } from '../../store/usePagination';
// import { getRandomSize } from '../../helpers';

function ProdImage({ prod, handleVisitProduct }: any) {
  const [isLoading, setIsLoading] = useState(true);
  // const { height } = getRandomSize(0, 0, 200, 400);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <>
      {isLoading && <Spinner size="large" />}
      <ProductImage
        src={prod.url || prod.highres || DEFAULT_IMAGE_PLACEHOLDER}
        // src={prod.img}
        // style={{ height }}
        onLoad={handleLoad}
        onClick={() => handleVisitProduct(prod.technicalDescription)}
        onError={handleError}
        preview={{
          mask: (
            <MaskInfo>
              <h3>
                {prod?.name ?? prod?.technicalDescription}
              </h3>
              <p>
                {prod?.subtitle ?? prod?.bANQADescriptionENG}
              </p>
            </MaskInfo>
          )
        }}
      />
    </>
  );
}

const Shop = () => {
  const { t, i18n } = useTranslation();
  const { products, filteredProducts, setFilteredProducts } = useProducts();
  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage } = usePagination();
  const {
    // data: productsS,
    fetchData: fetchAllProducts,
    loading: isFetchingAllProducts,
  } = useGetAllProducts();

  const {
    data: categories,
    fetchData: fetchAllCategories,
    loading: isFetchingAllCategories,
  } = useGetAllCategories();

  const {
    data: projects,
    fetchData: fetchProjects,
    loading,
  } = useGetAllProjects();

  const {
    data: subCategories,
    fetchData: fetchSubCategories,
    loading: isFetchingSubCategories,
  } = useGetSubCategory();

  const {
    data: bannerTexts,
    fetchData: fetchBannerTexts
  } = useGetAllBannerTexts();

  const {
    data: bannerImages,
    fetchData: fetchBannerImages
  } = useGetAllBannerImages();

  const {
    data: files,
    fetchData: fetchFiles
  } = useGetAllFiles();

  const navigate = useNavigate();

  const {
    selectedCategory,
    selectedType,
    subcategories,
    setSelectedCategory,
    setSelectedType,
    clearCategory
  } = useCategoryFilter();
  const [projectsAndProductsData, setProjectsAndProductsData] = useState<any[]>(
    []
  );
  const [projectsData, setProjectsData] = useState<ProductType[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    string[]
  >([]);

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjectsAndProductsData = projectsAndProductsData.slice(indexOfFirstItem, indexOfLastItem);
  const currentProductsData = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const currentProjectsData = projectsData.slice(indexOfFirstItem, indexOfLastItem);

  const totalFilteredProducts = useMemo(() => {
    const result = filteredProducts.filter((data: any) => {
      if (selectedSubCategory.length) {
        return selectedSubCategory.some((subCat: any) => data?.subCategories?.includes(subCat));
      }
      return data;
    });
    return result
  }, [filteredProducts, selectedSubCategory]);

  const sortedProducts = useMemo(() => {
    return currentProductsData.sort((a: any, b: any) => {
      if (a.productGroupCode < b.productGroupCode) {
        return -1;
      }
      if (a.productGroupCode > b.productGroupCode) {
        return 1;
      }
      return 0;
    }).filter((data: any) => {
      if (selectedSubCategory.length) {
        return selectedSubCategory.some((subCat: any) => data?.subCategories?.includes(subCat));
      }
      return data;
    });
  }, [products, selectedSubCategory, currentProductsData]);

  const allBanners = useMemo(() => {
    const modifiedBannerTexts = bannerTexts?.map((bannerText: any, index: number) => ({
      ...bannerText,
      image_id: `header-${index + 1}`,
    })) ?? [];

    return bannerImages?.filter((bannerImg: any) => bannerImg.id !== 'product').map((bannerImg: any) => {
      const matchedData = modifiedBannerTexts.find((text: any) => text.image_id === bannerImg.id) || {};
      return {
        ...bannerImg,
        imgUrl: bannerImg.url,
        ...matchedData,
      };
    });
  }, [bannerTexts, bannerImages]);

  const projectCategoryImage = useMemo(() => {
    return files?.filter((file: any) => file.id === 'product')[0].url
  }, [files]);

  useEffect(() => {
    (async () => {
      Promise.all([
        // await fetchAllProducts(),
        await fetchProjects(),
        await fetchAllCategories(),
        await fetchBannerTexts(),
        await fetchBannerImages(),
        await fetchFiles(),
      ]);
    })();
  },
    [
      fetchAllProducts,
      fetchProjects,
      fetchAllCategories,
      fetchBannerTexts,
      fetchBannerImages
    ]);

  useEffect(() => {
    if (projects && products) {
      modifyShopData();
      setFilteredProducts(products);
      setProjectsData(projects);
    }
  }, [projects, products]);

  const modifyShopData = useCallback(async () => {
    setProjectsAndProductsData([]);
    const items: Array<any> = [];

    for (let i = 0; i < products.length; i += PRODUCT_LIMIT_PER_MASONRY) {
      items.push({
        product: products.slice(i, i + PRODUCT_LIMIT_PER_MASONRY),
        project: {},
      });
    }

    for (let j = 0; j < projects.length; j++) {
      if (items[j]) {
        let temp = items[j];
        temp = {
          ...items[j],
          project: { ...projects[j], type: "project" },
        };
        items[j] = temp;
      } else {
        items.push({
          product: products.slice(j, j + PRODUCT_LIMIT_PER_MASONRY),
          project: { ...projects[j], type: "project" },
        });
      }
    }
    setProjectsAndProductsData(items);
  }, [projects, products]);

  const handleCategorize = async (code: string | null, type: string, docId?: string | number) => {
    setSelectedSubCategory([]);
    if (docId) {
      await fetchSubCategories(docId as string);
    }
    if (type === 'shop') {
      setItemsPerPage(3);
    } else {
      setItemsPerPage(30);
    }
    setCurrentPage(1);

    const newType = selectedType === type && selectedCategory === code ? "shop" : type;
    setSelectedType(newType);
    if (newType === "shop") {
      setItemsPerPage(3);
    }

    const newCategory = selectedCategory === code ? null : code;
    setSelectedCategory(newCategory);

    if (newCategory === null) {
      clearCategory();
      modifyShopData();
    } else {
      const filteredAllProducts = products.filter((product: any) => {
        if (typeof product.itemCategory === 'object') {
          return product.itemCategory.includes(code);
        } else {
          return product.itemCategory === code;
        }
      });
      setFilteredProducts(filteredAllProducts);
    }
  };

  const handleSubCategory = async (id: string) => {
    setSelectedSubCategory((prev: string[]) => {
      if (prev.includes(id)) {
        return prev.filter((subcat: string) => subcat !== id);
      }
      return [...prev, id];
    });

    // setProductsData(products.filter((data: any) => {
    //   if (Array.isArray(selectedSubCategory)) {
    //     return selectedSubCategory.some((subCat: any) => data?.subCategories.includes(subCat));
    //   }
    // }));
  };

  const handleVisitProduct = (technicalDescription: string) => {
    const transformedTechnicalDescription = transformToHyphenated(technicalDescription);
    navigate(`/products/${transformedTechnicalDescription}`);
  };

  const handleVisitProject = (id: string | number) => {
    navigate(`/projects/${id}`, { state: { fromProjects: true } });
  };

  const handleVisitBanner = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const renderContent = () => {
    switch (selectedType) {
      case "shop":
        return (
          <div key="shopRender">
            {currentPage === 1 && (
              <>
                <BannerImageContainer onClick={() => handleVisitBanner(allBanners[0]?.url)}>
                  <img
                    // src={`https://placehold.co/400/1E2D46/black?text=placeholder&font=playfair-display`}
                    src={allBanners && allBanners[0]?.imgUrl}
                  />
                  <div>
                    <p>{allBanners && allBanners[0]?.body}</p>
                  </div>
                </BannerImageContainer>
                <CategoryHighlights>
                  <h3>{t('title.category_highlights')}</h3>
                  <div>
                    {categories?.filter((cat: any) => cat.featured).map((category: any) => {
                      return (
                        <CategoryHighlightCard
                          key={`category_highlights_${category?.id}`}
                          onClick={() => handleCategorize(category.code, "product", category.id)}
                        >
                          <p>{selectedLanguage === 'ES' ? (category.nameEs || category.name) : category.name}</p>
                          <img
                            src={category?.url}
                          />
                        </CategoryHighlightCard>
                      )
                    })}
                  </div>
                </CategoryHighlights>
              </>
            )}
            {currentProjectsAndProductsData.map((data: any, mainIndex: number) => {
              const { project, product } = data;
              return (
                <div key={`${data.id}_${mainIndex}`}>
                  {mainIndex > 0 && (
                    <>
                      <MasonryLayout>
                        {(product ?? []).map((prod: ProductType, index: number) => (
                          <ProductImageContainer key={prod.id} delay={++index}>
                            <ProdImage
                              prod={prod}
                              handleVisitProduct={handleVisitProduct}
                            />
                            <OpenPreviewContainer show={prod.showInfo}>
                              {/* <Button
                          shape="circle"
                          icon={<ViewImageInfo />}
                          onClick={() =>
                            handleShowImageInfoProjProd(
                              prod.id,
                              mainIndex,
                              index - 1
                            )
                          }
                        /> */}
                            </OpenPreviewContainer>
                            <ProductImageOverlay show={prod.showInfo}>
                              <Row className="masked-row">
                                <Col sm={22}>
                                  <MaskInfo>
                                    <h3>
                                      {prod?.name ?? prod?.technicalDescription}
                                    </h3>
                                    <p>
                                      {prod?.subtitle ?? prod?.bANQADescriptionENG}
                                    </p>
                                  </MaskInfo>
                                </Col>
                              </Row>
                            </ProductImageOverlay>
                          </ProductImageContainer>
                        ))}
                      </MasonryLayout>
                      <MasonryMobileLayout>
                        <StyledCarousel
                          arrows
                          className="desktop-carousel"
                          prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
                          nextArrow={<SlickArrowRight currentSlide={0} slideCount={product?.length} />}
                        >
                          {(product ?? []).map((prod: ProductType, index: number) => (
                            <ProductImageContainer key={prod.id} delay={++index}>
                              <ProdImage
                                prod={prod}
                                handleVisitProduct={handleVisitProduct}
                              />
                              <OpenPreviewContainer show={prod.showInfo}>
                              </OpenPreviewContainer>
                              <ProductImageOverlay show={prod.showInfo}>
                                <Row className="masked-row">
                                  <Col sm={22}>
                                    <MaskInfo>
                                      <h3>
                                        {prod?.name ?? prod?.technicalDescription}
                                      </h3>
                                      <p>
                                        {prod?.subtitle ?? prod?.bANQADescriptionENG}
                                      </p>
                                    </MaskInfo>
                                  </Col>
                                </Row>
                              </ProductImageOverlay>
                            </ProductImageContainer>
                          ))}
                        </StyledCarousel>
                      </MasonryMobileLayout>
                    </>
                  )}
                  {Object.keys(project).length > 0 && (
                    <ProjectListContainer
                      key={`proj_${mainIndex}`}
                      gutter={[71, 71]}
                      style={{ margin: '5rem 0', overflowX: 'hidden' }}
                    >
                      <Col lg={12} md={24} xs={24} sm={24} style={{ padding: '0 8px' }}>
                        <div>
                          <StyledCarousel
                            arrows
                            className="desktop-carousel"
                            prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
                            nextArrow={<SlickArrowRight currentSlide={0} slideCount={project?.images?.length} />}
                          >
                            <ImageLayout
                              projectImg={project.url}
                              circles={project?.coordinates}
                              products={products ?? []}
                              productImages={project?.products ?? []}
                            />

                            {(project?.images ?? []).map((img: string, index: number) => (
                              <SubImageWrapperProj key={`${img}_${index}`}>
                                <SubImageProj src={img} />
                              </SubImageWrapperProj>
                            ))}
                          </StyledCarousel>
                        </div>
                      </Col>
                      <Col lg={12} md={24} xs={24} sm={24} style={{ padding: '0 8px' }}>
                        <ProjectDetailsContainer>
                          <div>
                            <h2>{project.title || project.name}</h2>
                            <p>{project.subtitle}</p>
                            <p>{project.description}</p>
                          </div>
                          <PrimaryButton
                            onClick={() => handleVisitProject(project.id)}
                            type="primary"
                          >
                            {`${t('button.view')} ${t('button.project')}`}
                          </PrimaryButton>
                        </ProjectDetailsContainer>
                      </Col>
                    </ProjectListContainer>
                  )}
                  {currentPage === 1 && (
                    <BannerImageContainer onClick={() => handleVisitBanner(allBanners[mainIndex + 1]?.url)}>
                      <img
                        // src={`https://placehold.co/400/1E2D46/black?text=placeholder&font=playfair-display`}
                        src={allBanners[mainIndex + 1]?.imgUrl}
                      />
                      <div>
                        <p>{allBanners[mainIndex + 1]?.body}</p>
                      </div>
                    </BannerImageContainer>
                  )}
                </div>
              );
            })}
          </div>
        );
      case "project":
        return (
          <div className="projectRender">
            {currentProjectsData.map((project: any, index: number) => (
              <ProjectListContainer
                key={`${project.id}_${index}`}
                gutter={[71, 71]}
              >
                <Col lg={12} md={24}>
                  <div>
                    <StyledCarousel
                      arrows
                      className="desktop-carousel"
                      prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
                      nextArrow={<SlickArrowRight currentSlide={0} slideCount={project?.images?.length} />}
                    >
                      <ImageLayout
                        projectImg={project.url}
                        circles={project?.coordinates}
                        products={products ?? []}
                        productImages={project?.products ?? []}
                      />

                      {(project?.images ?? []).map((img: string, index: number) => (
                        <SubImageWrapperProj key={`${img}_${index}`}>
                          <SubImageProj src={img} />
                        </SubImageWrapperProj>
                      ))}
                    </StyledCarousel>
                  </div>
                </Col>
                <Col span={12}>
                  <ProjectDetailsContainer>
                    <div>
                      <h2>{project.title || project.name}</h2>
                      <p>{project.subtitle}</p>
                      <p>{project.description}</p>
                    </div>
                    <PrimaryButton
                      onClick={() => handleVisitProject(project.id)}
                      type="primary"
                    >
                      VIEW PROJECT
                    </PrimaryButton>
                  </ProjectDetailsContainer>
                </Col>
              </ProjectListContainer>
            ))}
          </div>
        );
      case "product":
        if (selectedSubCategory.length > 0 && !totalFilteredProducts.length) {
          return <NoDataWrapper>
            <p>No data found.</p>
          </NoDataWrapper>
        }
        return (
          <div className="productRender">
            <ProductsMasonryLayout>
              {
                (selectedSubCategory.length > 0
                  ? totalFilteredProducts : sortedProducts)?.map((prod: ProductType, index: number) => (
                    <ProductImageContainer key={prod.id} delay={++index}>
                      <ProdImage
                        prod={prod}
                        handleVisitProduct={handleVisitProduct}
                      />
                      <ProductImageOverlay show={prod.showInfo}>
                        <Row className="masked-row">
                          <Col sm={22}>
                            <MaskInfo>
                              <h3>{prod?.name ?? prod?.technicalDescription}</h3>
                              <p>{prod?.subtitle ?? prod?.bANQADescriptionENG}</p>
                            </MaskInfo>
                          </Col>
                        </Row>
                      </ProductImageOverlay>
                    </ProductImageContainer>
                  ))
              }
            </ProductsMasonryLayout>
          </div>
        );
    }
  };

  const renderPagination = () => {
    switch (selectedType) {
      case 'project':
        return (
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={projectsData.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </PaginationWrapper>
        )
      case 'product':
        return (
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={totalFilteredProducts.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </PaginationWrapper>
        )
      default:
      // return (
      //   <PaginationWrapper>
      //     <Pagination
      //       current={currentPage}
      //       pageSize={itemsPerPage}
      //       total={projectsAndProductsData.length}
      //       onChange={(page) => setCurrentPage(page)}
      //     />
      //   </PaginationWrapper>
      // )
    }
  };

  if (loading || isFetchingAllProducts || isFetchingAllCategories) {
    return <Spinner size="large" />;
  }

  console.log('subCategories', subCategories)

  return (
    <MainContainer>
      <ProductsWrapper>
        <ProductsCategoryContainer sticky={Boolean(selectedCategory)}>
          {/* <ProductsTitle>Products</ProductsTitle> */}
          <ProductCategories>
            {(categories ?? []).map(
              (data: {
                id: string | number;
                url: string;
                code: string;
                name: string;
                nameEs: string;
                type: string;
              }) => (
                <>
                  <AvatarContainer
                    key={data.code}
                    onClick={() => handleCategorize(data.code, "product", data.id)}
                    selected={
                      data.code !== selectedCategory && selectedCategory !== null
                    }
                  >
                    <div>
                      <Avatar
                        size={{
                          xs: 100,
                          sm: 32,
                          md: 40,
                          lg: 110,
                          xl: 110,
                          xxl: 110,
                        }}
                        src={data?.url || DEFAULT_IMAGE_PLACEHOLDER}
                      />
                      <p>{selectedLanguage === 'ES' ? (data.nameEs || data.name) : data.name}</p>
                    </div>
                  </AvatarContainer>
                </>
              )
            )}
            <AvatarContainer
              key="project"
              onClick={() => handleCategorize("project", "project")}
              selected={
                "project" !== selectedCategory && selectedCategory !== null
              }
            >
              <div>
                <Avatar
                  size={{
                    xs: 100,
                    sm: 32,
                    md: 40,
                    lg: 110,
                    xl: 110,
                    xxl: 110,
                  }}
                  src={projectCategoryImage ?? DEFAULT_IMAGE_PLACEHOLDER}
                />
                <p>{selectedLanguage === 'ES' ? 'Proyectos' : 'Projects'}</p>
              </div>
            </AvatarContainer>
          </ProductCategories>
        </ProductsCategoryContainer>
        <div className="shop-wrapper">
          {isFetchingSubCategories ? (
            <Spinner size="large" />
          ) : (
            selectedCategory && selectedCategory !== 'project' && (
              <ProductSubcategories>
                {subcategories?.map((subCategory: SubcategoryType) => (
                  <StyledCheckbox
                    onChange={() => handleSubCategory(subCategory.id)}
                  >
                    {selectedLanguage === 'ES' ? (subCategory.nameEs || subCategory.name) : subCategory.name}
                  </StyledCheckbox>
                ))}
              </ProductSubcategories>
            )
          )}
          <ShopContent>
            {renderContent()}
            {renderPagination()}
          </ShopContent>
        </div>
      </ProductsWrapper>
    </MainContainer>
  );
};

export default Shop;
