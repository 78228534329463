import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Col, Row, notification } from "antd";
import emailjs from 'emailjs-com';
import BackArrow from "../../../components/icons/BackArrow";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import {
  BackButton,
  MainContainer,
  ProjectDescription,
  ProjectImageContainer,
  ProjectTitleContainer,
  StyledCarousel,
  SubImageBtnWrapper,
  SubImageProj,
  SubImageWrapper,
  SubImageWrapperProj,
} from "./style";
import { ProjectDetailsContainer } from "./style";
import { useGetProject } from '../../../hooks/projects/useGetAllProjects';
import useGetAllProducts from '../../../hooks/products/useGetAllProducts';
import Spinner from '../../../components/common/Spinner';
import ImageWithCircles from '../../../components/Image/ImageCoordinates';
import RequestModal from '../../../components/Request/RequestModal';
import ImageWithLoader from '../../../components/common/ImageWithLoader';
import { ProductType } from '../../../types/type';
import { useTranslation } from 'react-i18next';
import { SlickArrowLeft, SlickArrowRight } from '../../../components/Buttons/CarouselButtons';
import { HomeOutlined } from '@ant-design/icons';

const Project = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [modalVisible, setModalVisible] = useState(false);
  const [parentDimension, setParentDimension] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
  const { data: project, fetchData, loading } = useGetProject();
  const {
    data: productsData,
    fetchData: fetchAllProducts,
    loading: isFetchingAllProducts,
  } = useGetAllProducts();

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const handleVisitProduct = (id: string | number) => {
    navigate(`/products/${id ?? 1}`, { state: { fromProjects: true } });
  };

  const getParentImageDimension = (dimension: { width: number, height: number }) => {
    setParentDimension(dimension);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = () => {
    // Use EmailJS to send the email
    // emailjs.sendForm('banqa-es-app', 'template_c0d2vib', '#request-form', 'Yg8FbD0BW0diiDbw2')
    emailjs.sendForm('service_dizb97p', 'template_nidw3op', '#request-form', '-jixXRF3R8pwnsiVd')
      .then(() => {
        api.success({
          message: `Request sent successfully`,
          placement: 'top',
        });
      }, (error) => {
        api.error({
          message: `Email send error: ${error.text}`,
          placement: 'top',
        });
      });

    setModalVisible(false);
  };

  useEffect(() => {
    fetchData(id);
  }, [fetchData, id]);

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  if (loading || isFetchingAllProducts) return <Spinner size="large" />;

  return (
    <MainContainer>
      <Breadcrumb
        separator=">"
        items={[
          {
            title: <HomeOutlined />,
            href: 'https://banqa.es/'
          },
          {
            title: 'Shop',
            href: '/shop'
          },
          {
            title: ':id',
            href: '',
          },
        ]}
        params={{ id }}
      />
      {contextHolder}
      <BackButton onClick={() => navigate(-1)} type="text" icon={<BackArrow />}>
        {t('button.back_to_all_projects')}
      </BackButton>
      <ProjectImageContainer gutter={[21, 21]}>
        <Col lg={12} md={24}>
          <div>
            <StyledCarousel
              arrows
              className="desktop-carousel"
              prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
              nextArrow={<SlickArrowRight currentSlide={0} slideCount={project?.images?.length} />}
            >
              <ImageWithCircles
                imageUrl={project?.url}
                circles={project?.coordinates}
                products={productsData ?? []}
                dimension={getParentImageDimension}
              />
              {(project?.images ?? []).map((img: string, index: number) => (
                <SubImageWrapperProj key={`${img}_${index}`}>
                  <SubImageProj src={img} />
                </SubImageWrapperProj>
              ))}
            </StyledCarousel>
          </div>
        </Col>
        <Col lg={12} md={24}>
          <Row
            gutter={[19, 19]}
          >
            {productsData?.filter((product: any) => project && project?.products.includes(product.id)).map((product: ProductType) => (
              <SubImageWrapper height={parentDimension.height} lg={12} md={24} key={product.id}>
                {/* <SubImage src={product.highres} /> */}
                <ImageWithLoader src={product.url || product.highres || product.medium as string} />
                <SubImageBtnWrapper>
                  <SecondaryButton
                    onClick={() => handleVisitProduct(product.id)}
                    type="primary"
                  >
                    {t('button.view')}
                  </SecondaryButton>
                </SubImageBtnWrapper>
              </SubImageWrapper>
            ))}
          </Row>
        </Col>
      </ProjectImageContainer>
      <ProjectDetailsContainer gutter={15}>
        <Col lg={18}>
          <ProjectTitleContainer>
            <div>
              <h1>{selectedLanguage === 'ES' ? (project?.nameEs || project?.name) : project?.name}</h1>
              <h3>{selectedLanguage === 'ES' ? (project?.subtitleEs || project?.subtitle) : project?.subtitle}</h3>
            </div>
          </ProjectTitleContainer>
          <ProjectDescription>{selectedLanguage === 'ES' ? (project?.descriptionEs || project?.description) : project?.description}</ProjectDescription>
        </Col>
        <Col lg={6}>
          <strong>{t('project.how_can_we_help')}</strong>
          <p>
            {t('project.here_to_respond')}
          </p>
          <PrimaryButton type="primary" onClick={() => setModalVisible(true)}>{t('button.request_call')}</PrimaryButton>
        </Col>
      </ProjectDetailsContainer>
      <RequestModal
        visible={modalVisible}
        onCancel={handleModalCancel}
        onFinish={handleFormSubmit}
      />
    </MainContainer>
  );
};

export default Project;
