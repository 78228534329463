import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../firebase/config";
import { useCategoryFilter } from '../../store/useCategoryFilter';
import { SubcategoryType } from '../../types/type';

export default function useGetAllCategories(): any {
  const [user, setUser] = useState<any>();
  const [ids, setIds] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = query(collection(db, "categories"));

      await getDocs(docRef)
        .then((docSnap) => {
          const databaseInfo: any[] = [];
          const dataIds: string[] = [];

          docSnap.forEach(async (docsnapshot) => {
            databaseInfo.push({
              ...docsnapshot.data(),
              id: docsnapshot.id,
            });
            dataIds.push(docsnapshot.id);
          });

          setIds(dataIds);
          setUser(databaseInfo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return {
    data: user,
    ids,
    fetchData: refetch,
    loading,
  };
}

export function useGetCategory(): any {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback((id: string) => {
    function fetchData() {
      setLoading(true);
      const d = doc(db, "categories", id);
      getDoc(d).then((docSnap) => {
        if (docSnap.exists()) {
          setUser({
            ...docSnap.data(),
            id: docSnap.id,
          });
          return {
            ...docSnap.data(),
            id: docSnap.id,
          };
        }
        return null;
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  return {
    data: user,
    fetchData: refetch,
    loading,
  };
}

export function useGetSubCategory() {
  const { setSubcategories } = useCategoryFilter();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback((id: string) => {
    async function fetchData() {
      setLoading(true);
      const d = doc(db, "categories", id);
      const subCatCollectionRef = collection(d, 'subcategories');
      const q = query(subCatCollectionRef);
      const querySnapshot = await getDocs(q);
      const subCategoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(subCategoriesData);
      setSubcategories(subCategoriesData as SubcategoryType[]);
      setLoading(false);
    }
    fetchData();
  }, []);

  return {
    data,
    fetchData: refetch,
    loading,
  };
}
